import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SoleCopyright from "./SoleCopyrightComponent";

const Welcome: React.FC = () => {
    return (
        <Container>
            <h1>Welcome!</h1>
            <SoleCopyright/>
        </Container>
    )
};

export default Welcome;
