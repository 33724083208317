import red from '@material-ui/core/colors/red';
import {createMuiTheme} from '@material-ui/core/styles';

// A custom theme for StartSOLE
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#28A79A',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fbfdfa',
        },
    },
});

export default theme;
