import React from 'react';
import './App.css';
import './shared/ParseUtil';
import {BrowserRouter} from "react-router-dom";
import Main from "./components/MainComponent";
// import {Provider} from "react-redux";
// import {configureStore} from "./redux/configureStore";

// const store = configureStore();

const App: React.FC = () => {
    return (
        // <Provider store={store}>
            <BrowserRouter>
                <div>
                    <Main/>
                </div>
            </BrowserRouter>
       // </Provider>
    );
};

export default App;
