import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Join from "./JoinComponent";
import Welcome from "./WelcomeComponent";

const Main: React.FC = (props: any) => {
    return (
        <Switch>
            <Route path="/join/:id" component={Join}/>
            <Route path="/" component={Welcome}/>
            <Redirect to="/"/>
        </Switch>
    )
};

export default Main;
