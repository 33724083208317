import React, {useEffect, useState} from 'react';
import Parse from 'parse';
import {match} from "react-router-dom";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SoleCopyright from "./SoleCopyrightComponent";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    paragraph: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
    },
}));

interface Identifiable {
    id: string
}

interface JoinProps {
    match?: match<Identifiable>
}

const useWaitForMeeting = (soleId: string): boolean => {
    const [meetingIsRunning, setMeetingIsRunning] = useState(false);

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const check = async () => {
        while (true) {
            console.log('check meeting');
            const isRunning = await Parse.Cloud.run('bbbcontroller.isMeetingRunning', {
                soleId: soleId
            }, {
            });
            setMeetingIsRunning(isRunning);
            await sleep(5000);
        }
    };

    useEffect(() => {
        check();
    }, []);

    return meetingIsRunning;
}

const useJoinMeeting = (soleId: string): [string | undefined, (name: string) => void] => {
    const [joinUrl, setJoinUrl] = useState<string | undefined>(undefined);

    const join = async (name: string) => {
        console.log('join ' + name);

        try {
            const url = await Parse.Cloud.run('bbbcontroller.getParticipantUrl', {
                soleId: soleId,
                name: name
            }, {
            });
            setJoinUrl(url);
        } catch (error) {
            console.log(error);
        }
    };

    return [joinUrl, join];
};

const useStateWithLocalStorage = (localStorageKey: string): [string, (value: string) => void] => {
    const [value, setValue] = React.useState(
        localStorage.getItem(localStorageKey) || ''
    );

    useEffect(() => {
        localStorage.setItem(localStorageKey, value);
    }, [value]);
    return [value, setValue];
};

const Join: React.FC<JoinProps> = (props) => {
    const classes = useStyles();
    const [name, setName] = useStateWithLocalStorage('username');
    const [joinUrl, join] = useJoinMeeting(props.match?.params.id || '');
    const meetingIsRunning = useWaitForMeeting(props.match?.params.id || '');

    function handleSubmit(event: any) {
        event.preventDefault();
        console.log('Name:', name);
        join(name);
    }

    if (joinUrl) {
        { window.location.href = joinUrl }
        return (
            <div/>
        );
    } else {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <img src="/images/logos/animated-logo-no-repeat.gif" alt="logo"/>
                    <Typography component="h1" variant="h5">
                        {meetingIsRunning ? 'Join a Virtual SOLE' : 'Waiting for your SOLE to begin'}
                    </Typography>

                    <form className={classes.form} onSubmit={handleSubmit} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            value={name}
                            onChange={(e: any) => setName(e.target.value)}
                        />
                        <Button
                            disabled={!meetingIsRunning}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Join In
                        </Button>
                    </form>

                    {!meetingIsRunning &&
                    <Typography component="p" className={classes.paragraph}>
                        Please leave this window open and you'll be connected when your SOLE starts.
                    </Typography>
                    }
                </div>
                <Box mt={8}>
                    <SoleCopyright/>
                </Box>
            </Container>
        );
    }
};

export default Join;
